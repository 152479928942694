import React, { ReactNode } from 'react';
import useAuth from '../../hooks/useAuth';

interface PermissionGuardProps {
  children: ReactNode;
  role: 100 | 101;
}

const PermissionGuard = ({ children, role }: PermissionGuardProps) => {
  const { user } = useAuth();
  if (user && user.usr_role === role) {
    return <>{children}</>;
  }
  return <div>You dont have access</div>;
};

export default PermissionGuard;
