import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import PermissionGuard from './components/PermissionGuard';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

const AdminDashboard = Loadable(lazy(() => import('./pages/admin-dashboard')));
const KeysDashboard = Loadable(lazy(() => import('./pages/keys-dashboard')));
const Transactions = Loadable(lazy(() => import('./pages/transactions')));

const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'manage-users',
        element: (
          <PermissionGuard role={100}>
            <AdminDashboard />
          </PermissionGuard>
        ),
      },
      {
        path: 'managed-keys',
        element: (
          <PermissionGuard role={101}>
            <KeysDashboard />
          </PermissionGuard>
        ),
      },

      {
        path: 'transactions',
        element: (
          <PermissionGuard role={101}>
            <Transactions />
          </PermissionGuard>
        ),
      },
    ],
  },
];

export default routes;
