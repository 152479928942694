import { useContext } from 'react';
import { AuthContext } from '../providers/auth/AuthProvider';

const useAuth = () => {
  const { user, setUser, logout } = useContext(AuthContext);

  return { user, isLoggedIn: Boolean(user), setUser, logout };
};

export default useAuth;
