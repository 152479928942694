import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-hot-toast';

interface ErrorType {
  error: {
    err_id: number;
    err_message: string;
    has_ph: number;
    id: number;
  };
}
export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}
//let isRefreshing = false;
//let failedQueue: any[] = [];
/* const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
}; */
const api = axios.create({
  baseURL: 'http://188.40.170.43',
  timeout: 10000,
});

api.interceptors.request.use((req) => {
  if (localStorage.getItem('cct')) {
    req.headers.Authorization = `Bearer ${localStorage.getItem('cct')}`;
  }
  return req;
});
/* 
api.interceptors.response.use(
  (resp) => resp,
  (error) => {
    const originalRequest = error.config;
    const that = this;

    if (error.response && error.response.status === 401) {
      // this.rootStore.user.logout()
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({
            resolve,
            reject,
          });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((err) =>
            // this.rootStore.user.logout()
            Promise.reject(err),
          );
      }
      
      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem('refresh_token');
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.REACT_APP_URL}/api/auth/refresh`, {
            refreshToken,
          })
          .then(({ data }) => {
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
            originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
            processQueue(null, data.access_token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            that.rootStore.auth.logout();
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    if (error.response.status === 405) {
      console.log(error.response);
      toast.error(error.response.data.message);
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Unexpected error');
    }
    return Promise.reject(new Error('Unexpected error'));
  },
); */

export const response = <T extends ErrorType>(data: AxiosResponse<unknown>) => {
  const result = data.data as T;
  if (result.error) {
    toast.error(result.error.err_message);
    throw new Error(result.error.err_message);
  }

  if (!result && data.status !== 200 && data.status !== 201) {
    toast.error('Unexpected error');
    throw new Error('Unexpected error');
  }
  return result;
};

export const call = <E, T = {}>(http: HttpMethod, url: string, data?: T) => {
  if (http === HttpMethod.POST || http === HttpMethod.PUT)
    return api[http](url, data).then((res: AxiosResponse<T>) =>
      response<E & ErrorType>(res),
    );

  return api[http](url, {
    responseType: 'json',
  }).then((res: AxiosResponse<T>) => response<E & ErrorType>(res));
};
