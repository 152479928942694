import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useMutation } from "react-query";
import { call, HttpMethod } from "../../Network";
import jwt_decode from "jwt-decode";
import useAuth from "../../hooks/useAuth";
import { UserType } from "../../providers/auth/AuthProvider";

interface LoginData {
  username: string;
  password: string;
}

const Login: FC = () => {
  const { setUser } = useAuth();
  const login = async (vals: LoginData) =>
    call<{ access_token: string }>(HttpMethod.POST, "/api/auth/login", vals);
  const { mutateAsync } = useMutation(login, {
    onSuccess: (data) => {
      const decoded = jwt_decode<UserType>(data.access_token);
      setUser(decoded);
      localStorage.setItem("cct", data.access_token);
    },
  });

  const initialValues = {
    username: "",
    password: "",
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="sm" sx={{ py: "80px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 8,
            }}
          >
            <RouterLink to="/">
              {/* <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              /> */}
              {/*   <img src={LogoSVG} alt="" width={60} /> */}
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Login
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Connect cloud platforma
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    "& > img": {
                      maxHeight: "100%",
                      width: "auto",
                    },
                  }}
                >
                  {/* <img
                    alt="Auth platform"
                    src={platformIcons[platform]}
                  /> */}
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              />
              <Divider sx={{ my: 3 }} />

              <Formik
                initialValues={initialValues}
                onSubmit={(vals) => mutateAsync(vals)}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }): JSX.Element => (
                  <form noValidate onSubmit={handleSubmit}>
                    <TextField
                      autoFocus
                      error={Boolean(touched.username && errors.username)}
                      fullWidth
                      helperText={touched.username && errors.username}
                      label="Email"
                      margin="normal"
                      name="username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.username}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    {/*   {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )} */}
                    <Box sx={{ mt: 2 }}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Login
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
