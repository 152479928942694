import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import useAuth from '../hooks/useAuth';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = observer(({ children }) => {
  const { isLoggedIn } = useAuth();
  console.log(isLoggedIn);
  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
});

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
