import React, { createContext, useEffect, useState } from 'react';

import jwt_decode from 'jwt-decode';
export interface UserType {
  username: string;
  usr_ID: string;
  usr_role: 100 | 101;
}

export const AuthContext = createContext<{
  user: UserType | null;
  setUser: React.Dispatch<React.SetStateAction<UserType>>;
  logout: () => void;
}>({
  user: null,
  setUser: () => {},
  logout: () => {},
});
const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<UserType | null>(null);
  const logout = () => setUser(null);
  useEffect(() => {
    const token = localStorage.getItem('cct');
    if (token) setUser(jwt_decode(token));
  }, []);
  return (
    <AuthContext.Provider value={{ user, setUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
