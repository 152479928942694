import type { FC } from "react";
import { useRoutes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { CssBaseline, ThemeProvider } from "@mui/material";
import useScrollReset from "./hooks/useScrollReset";
import routes from "./routes";
import { ReactQueryDevtools } from "react-query/devtools";
import { createTheme } from "./theme";

import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const App: FC = () => {
  const content = useRoutes(routes);

  useScrollReset();

  const theme = createTheme({
    direction: "ltr",
    responsiveFontSizes: true,
    mode: "dark",
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position="top-center" />
      <QueryClientProvider client={queryClient}>
        {content}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
